<template>
  <div class="mile-tree">
    <div v-show="!isStarted">
      <img :src="getTreeImageUrl(initImageName)" :class="IMAGE_CLASS" alt="" />
    </div>

    <transition-group
      v-show="isStarted && !isStaging"
      name="mile-tree-staging"
      tag="div">
      <div
        v-for="(treeImage, treeIndex) in TREE_IMAGES"
        :key="`key-${treeIndex - 1}`">
        <template v-if="isAnimate">
          <img
            v-show="treeImage === animationName"
            :src="getTreeImageUrl(treeImage, true)"
            :class="IMAGE_CLASS"
            alt="" />
        </template>
        <template v-else-if="!isAnimate">
          <img
            v-show="treeImage === imageName"
            :src="getTreeImageUrl(treeImage)"
            :class="IMAGE_CLASS"
            alt="" />
        </template>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    isStarted: {
      type: Boolean,
      default: false
    },

    // WEBPの再レンダリングのためのフラグ（ループさせないデータのため）
    isStaging: {
      type: Boolean,
      default: false
    },

    isAnimate: {
      type: Boolean,
      default: false
    },

    initImageName: {
      type: String,
      default: '01.png'
    },

    imageName: {
      type: String,
      default: '01.png'
    },

    animationName: {
      type: String,
      default: '01-animate.webp'
    },

    stageId: {
      type: Number,
      default: 1
    },

    stageUpTimes: {
      type: Number,
      default: 1
    }
  },

  setup: (props) => {
    const TREE_IMAGES = [
      '01-animate.webp',
      '02-animate.webp',
      '03-animate.webp',
      '04-animate.webp',
      '05-animate.webp',
      '01.png',
      '02.png',
      '03.png',
      '04.png',
      '05.png'
    ];
    const IMAGE_CLASS = 'mile-tree__img';

    const getTreeImageUrl = (value, hasQuery = false) => {
      const name = hasQuery
        ? `${value}?${props.stageId}${props.stageUpTimes}`
        : value;
      return `/assets/img/mile/tree/${name}`;
    };

    return {
      TREE_IMAGES,
      IMAGE_CLASS,
      getTreeImageUrl
    };
  }
});
</script>

<style lang="scss" scoped>
.mile-tree {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -220px;
  width: 100%;
  height: 233px;
}

.mile-tree__img {
  width: 186px;
  height: 233px;
}

.mile-tree-staging {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.25s linear;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
