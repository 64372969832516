var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mile-tree" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isStarted,
              expression: "!isStarted",
            },
          ],
        },
        [
          _c("img", {
            class: _vm.IMAGE_CLASS,
            attrs: { src: _vm.getTreeImageUrl(_vm.initImageName), alt: "" },
          }),
        ]
      ),
      _c(
        "transition-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isStarted && !_vm.isStaging,
              expression: "isStarted && !isStaging",
            },
          ],
          attrs: { name: "mile-tree-staging", tag: "div" },
        },
        _vm._l(_vm.TREE_IMAGES, function (treeImage, treeIndex) {
          return _c(
            "div",
            { key: "key-" + (treeIndex - 1) },
            [
              _vm.isAnimate
                ? [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: treeImage === _vm.animationName,
                          expression: "treeImage === animationName",
                        },
                      ],
                      class: _vm.IMAGE_CLASS,
                      attrs: {
                        src: _vm.getTreeImageUrl(treeImage, true),
                        alt: "",
                      },
                    }),
                  ]
                : !_vm.isAnimate
                ? [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: treeImage === _vm.imageName,
                          expression: "treeImage === imageName",
                        },
                      ],
                      class: _vm.IMAGE_CLASS,
                      attrs: { src: _vm.getTreeImageUrl(treeImage), alt: "" },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }